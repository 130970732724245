import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import List from "../../components/custom-widgets/list";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

import showMktoForm from "../../helpers/showMktoForm";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const CommercialTermLoans = () => {
  const title = "Commercial Term Loans";
  const description =
    "Looking for commercial term loans? Discover WaFd Bank, offering payments that are customized for your business. Get in touch with a commercial banker today.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-term-loans/hero-commercial-term-loans-01052023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/commercial-banking/commercial-term-loans/hero-commercial-term-loans-01052023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/commercial-banking/commercial-term-loans/hero-commercial-term-loans-01052023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/commercial-banking/commercial-term-loans/hero-commercial-term-loans-01052023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/commercial-banking/commercial-term-loans/hero-commercial-term-loans-01052023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/commercial-banking/commercial-term-loans/hero-commercial-term-loans-01052023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-term-loans/hero-commercial-term-loans-01052023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-term-loans"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-commercial-term-loans-01052023-250.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "commercial-term-loans-hero",
    ...getHeroImgVariables(imgData),
    altText: "Two people in hardhats talking by shipping containers.",
    chevronBgClass: "bg-dark-green-01",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Your Plans Need a Loan That Fits"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Commercial Term Loans"
    }
  ];

  const commercialTermLoansListData = {
    accountName: "term-loans-list",
    items: [
      {
        id: 1,
        text: "Payments are customized for each business' cash flow. Typical loan term or amortization is 1 - 7 years"
      },
      {
        id: 2,
        text: "For multiple and ongoing equipment purchase needs, we can provide a CapEx line of credit, which will convert to a term loan at the end of the budget cycle"
      }
    ]
  };
  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Commercial Term Loans</h1>
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <StaticImage
              src="../../images/thumbnail-commercial-term-loans-022824.jpg"
              alt="Man working with commercial printing machine."
              placeholder="blurred"
              quality="100"
              className="border-radius-12"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">
              If your business needs to purchase equipment, make capital improvements, or finance future expansion, a
              term loan is a practical solution.
            </h3>
            <List {...commercialTermLoansListData} />
            <button
              id="contact-commercial-banking-cta"
              className="btn btn-primary w-100 w-sm-auto no-min-width"
              onClick={(e) => {
                e.preventDefault();
                showMktoForm(1067);
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </section>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CommercialTermLoans;
